.WelcomeText {
    color: red;
    font-size: 50px;
    padding-top: 80px;
    padding-bottom: 20px;
    text-align: center;
  }

.Fiftywords {
    font-size: 20px;
    text-align: center;
}